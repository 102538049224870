
// Fonts
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  src: local('Raleway Thin'), local('Raleway-Thin'), url('fonts/1Ptsg8zYS_SKggPNwE44Q4FqPfE.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin aaa*/
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  src: local('Raleway Thin'), local('Raleway-Thin'), url('fonts/1Ptsg8zYS_SKggPNwE44TYFq.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url('fonts/1Ptrg8zYS_SKggPNwPIsWqhPAMif.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url('fonts/1Ptrg8zYS_SKggPNwPIsWqZPAA.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('fonts/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('fonts/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('fonts/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('fonts/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('fonts/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('fonts/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('fonts/KFOlCnqEu92Fr1MmSU5fBBc4.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('fonts/KFOmCnqEu92Fr1Mu72xKOzY.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('fonts/KFOmCnqEu92Fr1Mu5mxKOzY.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('fonts/KFOmCnqEu92Fr1Mu7mxKOzY.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('fonts/KFOmCnqEu92Fr1Mu4WxKOzY.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('fonts/KFOmCnqEu92Fr1Mu7WxKOzY.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('fonts/KFOmCnqEu92Fr1Mu7GxKOzY.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('fonts/KFOmCnqEu92Fr1Mu4mxK.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('fonts/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('fonts/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('fonts/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('fonts/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('fonts/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('fonts/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('fonts/KFOlCnqEu92Fr1MmEU9fBBc4.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/KFOlCnqEu92Fr1MmWUlfBBc4.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
/*
@import url('https://fonts.googleapis.com/css?family=Nunito');
*/
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Regular'), local('Nunito-Regular'), url('fonts/XRXV3I6Li01BKofIOOaBXso.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Regular'), local('Nunito-Regular'), url('fonts/XRXV3I6Li01BKofIMeaBXso.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Regular'), local('Nunito-Regular'), url('fonts/XRXV3I6Li01BKofIOuaBXso.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Regular'), local('Nunito-Regular'), url('fonts/XRXV3I6Li01BKofIO-aBXso.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Regular'), local('Nunito-Regular'), url('fonts/XRXV3I6Li01BKofINeaB.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~vuetify/dist/vuetify' ;
.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}


// D3 relations chart
.node { stroke: #222; stroke-width: 1.5px;
  
 }
.node.actor { fill: #888; }
.node.movie { fill: #BBB; }
.link { stroke: #999; stroke-opacity: .6; stroke-width: 1px; }

.links line {
stroke: #999;
stroke-opacity: 0.6;
}
.links line.acquireline {
  stroke: gold;
  stroke-opacity: 0.6;
  }

.nodes circle {
stroke: #fff;
stroke-width: 1.5px;
cursor: pointer;
}

text {
    font-family: sans-serif;
    font-size: 10px;
  }

  .tanglednode {
    stroke-linecap: round;
  }
  .tanglednodeselected {
    stroke-linecap: round;
  }
  .tanglednodetextselected {
    font-weight: bold;
    font-size: 12px;
    fill: $red;
  }
  .tanglednodetextnotfiltered {
    font-style: italic;
    fill: gray;
  }
  .tanglednodetextnotfilteredstroke {
    font-style: italic;

  }
  .tangledlink {
    fill: none;
  }
  
.relations-container {
  flex-flow: column;
  flex-grow:1;
  flex-shrink: 1;
}
.relations-container .v-card__title {
  padding-top: 0px;
  padding-bottom: 0px;
}
.relations-container .v-input--selection-controls {
  margin-top: 0px;
}
.relations-container .v-input__slot {
  margin-bottom: 0px;
}
.relations-container label {
  margin-bottom: 0px;
}

// objects
/*
.OPC {

}
.OPERATION {

}
.JOB {
  
} 
.PROC {

}
.PARM {
}
.PGM {

}
.COPYBOOK {

}

.OPC {
}
*/
.v-navigation-drawer a:hover {
  text-decoration: none;
}

.source-container {
  height: 500px;
  width: 100%;
}
.no-menu-layout {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-flow: column;
}
.no-menu-layout .source-container {
  flex-grow : 1;
  flex-shrink: 1;
  height: 100%;
  width: 100%;
  min-width:0px;
  min-height:0px;
}
.no-menu-layout .source-container-inner {

  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;

}

.relations-chart-container {
  height: 500px;
  width: 100%;
}

.no-menu-layout .relations-chart-container {
  flex-grow : 1;
  flex-shrink: 1;
  height: 100%;
  width: 100%;
}
.no-menu-layout .relations-container-inner {

  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-flow: column;

}
.invocations-chart-container {
  height: 500px;
  width: 100%;
}

.no-menu-layout .invocations-chart-container {
  height: 800px;
  width: 100%;
}
.usage-chart-container {
  height: 500px;
  width: 100%;
}
.dashboard-msu-chart-container {
  height: 300px;
  width: 100%;
}
.bar {
  fill: $primary;
}

.highlighted em {
  font-weight: bold;
}

header.v-toolbar > div {
  padding-left: 5px;
}